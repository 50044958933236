@import 'partials/variables';
@import 'partials/mixins';
@import 'fonts';

html {
  font-size: 18px;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family, sans-serif !important;
  background-color: $color-bg !important;
  color: $color-main !important;
  font-size: $font-size-big !important;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: contain;
  user-select: none;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

input, button, select, optgroup, textarea {
  font-family: $font-family, sans-serif !important;
  font-size: $font-size-normal !important;
}

.custom-select {
  background-color: $color-default !important;
}

.ng-select-container {
  background-color: $color-default !important;
  padding: 0 $small-gap;
}

.ng-dropdown-panel {
  background-color: $color-bg !important;
}

select {
  color: $color-main !important;
  border-color: $color-default !important;
}

a {
  font-size: $font-size-small;
  font-weight: 600;
  color: $color-primary !important;
}

.nav-link, .sidenav-link, .widget-simple a {
  color: $color-default !important;
}

.card {
  background-color: $color-content !important;
  border-color: $color-content !important;
  color: $color-main;
}

.child-container {
  height: 100%;
}

.tabs {
  display: flex;
  height: $tabs-height;
  align-items: center;
  text-align: center;
  padding-bottom: $normal-gap;

  .tab {
    flex: 1;
    padding: $normal-gap 0;
    background-color: transparent;
    font-size: $font-size-normal;
    font-weight: 600;
    border-bottom: 1px solid $color-default;

    &__link {
      font-size: $font-size-normal;
      color: $color-default !important;
    }

    &--active {
      background-color: $color-accent;
      border-top-right-radius: $br-big;
      border-top-left-radius: $br-big;
      color: $color-content;
      border-color: $color-accent;
    }
  }
}

.invalid-feedback {
  display: block !important;
  color: $color-invalid !important;
}

.ng-touched.ng-invalid:not(form) {
  border-color: $color-invalid;
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

.ng-touched.ng-valid:not(form) {
  border-color: $color-valid;
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

div.form-group {
  width: 100%;
  margin-bottom: 0;
  padding: $small-gap;

  &--top-border {
    margin-top: $small-gap*3;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }

  &__text {
    font-size: $font-size-small;
    color: $color-calm;
  }

  &__text + &__text {
    padding-top: $medium-gap;
  }
}

.title-form-group {
  margin-bottom: $small-gap;
  font-size: $font-size-normal;
  font-weight: 600;
}

.sc-auth {
  height: 100%;
  padding: $medium-gap;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ui-logo {
    padding-bottom: $small-gap*3;
    text-align: center;
  }

  > * {
    width: 100%;
  }

}

.sc-wide {
  width: 100%;
}

.not-call {
  padding: $small-gap 0;
}

.widget-tile {

  &--square > div {
    width: 152px !important;
    height: 178px !important;
  }

  &--rectangle > div {
    width: 100% !important;
    height: 100px !important;
  }

}

.connect-capsule--sub > div {

  $gap: $medium-gap *3;

  margin-left: $gap;

  &::before {
    height: 40px;
    width: 16px;

    display: block;
    position: absolute;
    left: -$medium-gap;
    top: -$normal-gap;
    content: '';
    background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'16\' height=\'40\' viewBox=\'0 0 16 40\' fill=\'none\'%3e%3crect width=\'2\' height=\'37\' fill=\'%2366686D\'/%3e%3crect y=\'37\' width=\'2\' height=\'9\' transform=\'rotate(-90 0 37)\' fill=\'%2366686D\'/%3e%3ccircle cx=\'12\' cy=\'36\' r=\'3\' stroke=\'%2366686D\' stroke-width=\'2\'/%3e%3c/svg%3e');
  }
}

.btn-greater {
  padding-top: .67rem !important;
  padding-bottom: .67rem !important;
}

.ng-value-icon {
  padding-right: $small-gap;
}

.sc-icon-upload {
  @include size($icon-upload-size);

  position: relative;
  background: $color-secondary;
  border-radius: 5px;

  &::before {
    @include size(48px);
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'48\' height=\'48\' viewBox=\'0 0 48 48\' fill=\'none\'%3e%3cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M6 8C6 6.89543 6.89543 6 8 6H40C41.1046 6 42 6.89543 42 8V40C42 41.1046 41.1046 42 40 42H8C6.89543 42 6 41.1046 6 40V8Z\' stroke=\'white\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3e%3cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M17 20C18.6569 20 20 18.6569 20 17C20 15.3431 18.6569 14 17 14C15.3431 14 14 15.3431 14 17C14 18.6569 15.3431 20 17 20Z\' stroke=\'white\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3e%3cpath d=\'M42 30L32 20L10 42\' stroke=\'white\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'/%3e%3c/svg%3e');
  }
}

.modal-content {
  background-color: $color-content;
}

.modal-header {
  display: none !important;
}

.modal-body {
  font-family: $font-family, sans-serif !important;
  padding: $big-gap;
  margin: 0 $medium-gap;
  text-align: center;

  img {
    padding-bottom: $big-gap;
  }

  &__title {
    padding-bottom: $normal-gap;
    font-size: $font-size-big;
    font-weight: 600;
    color: $color-main;
  }

  &__text {
    padding-bottom: $big-gap;
    font-size: $font-size-normal;
    font-weight: normal;
    //color: $color-secondary;
  }

  .btn {
    font-size: $font-size-big;
    font-weight: 600;
    width: 100%;
    padding: 0.7rem 1.125rem;
  }

  .btn + .btn {
    margin-top: $medium-gap;
  }

  .btn-danger {
    background-color: $color-alarm;
    border-color: $color-alarm;
  }
}

.swiper-pagination-bullet {
  height: 10px !important;
  width: 10px !important;
  border: 1px solid $color-content !important;
  background: none !important;
  opacity: 1 !important;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: $color-content !important;
}

.swiper-container-cube .swiper-cube-shadow {
  background: none !important;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 116px !important;
  bottom: 0 !important;
  top: auto !important;
  left: 71px !important;
}

div.swiper-pagination.swiper-pagination-progressbar span.swiper-pagination-progressbar-fill {
  background-color: $color-main !important;
}

.swiper-slide-shadow-left, .swiper-slide-shadow-right {
  background: none !important;
}

.btn {
  height: 48px;
}

.btn-primary {
  background: $color-primary !important;
}

.btn-outline-primary {
  box-shadow: 0 0 0 1px $color-primary inset !important;
  color: $color-primary !important;
  border-color: transparent !important;
}

.btn-secondary {
  background: $color-secondary !important;
}

.btn-default {
  color: $color-default !important;
}

.btn-active {
  background: $color-accent !important;
}

button.btn-primary.disabled, button.btn-primary:disabled {
  background: $color-primary !important;
}

button.btn-secondary.disabled, button.btn-secondary:disabled {
  background: $color-secondary !important;
}

.scan__slider {
  div.swiper-container {
    overflow: visible !important;
  }

  div.swiper-slide-prev, div.swiper-slide-next {
    filter: blur(1.4px);
    opacity: .4;
  }
}

.mapbox-map > *, .map-tag {
  font-family: $font-family !important;
}

ui-location-info {
  min-width: 0;
}

.switcher-label {
  color: $color-main !important;
}

.form-control {
  color: $color-main !important;
  background-color: $color-bg !important;
  border-color: $color-default !important;
}

.alert-warning {
  color: $color-main !important;
}

.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: $color-default !important;
  color: $color-content !important;
}

