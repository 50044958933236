//font sizes
$font-size-large: 1.777rem; //32px
$font-size-increased: 1.333rem; //24px
$font-size-big: 1rem; //18px
$font-size-normal: 0.888rem; //16px
$font-size-small: 0.777rem; //14px
$font-size-mini: 0.666rem; //12px
$font-size-micro: 0.5555rem; //10px

//colors
$color-invalid: #f43a3a;
$color-valid: #02bc77;

$color-active: #2cb573;
$color-inactive: #dadada;

$color-main: #303134;
$color-secondary: #a6acb7;
$color-default: #a6acb7;
$color-primary: #f00908;
$color-alarm: #f43a3a;
$color-accent: #f00908;

$color-calm: #6fcf97;
$color-warning: #f2c94c;

$color-content: #ffffff;
$color-bg: #f4f4f4;

//gaps
$big-gap: 24px;
$medium-gap: 16px;
$normal-gap: 12px;
$small-gap: 8px;

//icons
$icon-upload-size: 96px;

//border-radius
$br-medium: 3px;
$br-big: 5px;

//sizes
$footer-height: 53px;
$footer-height-ios: 80px;
$navbar-height: 53px;
$nav-top-height: 48px;

$location-tool-size: 65px;
$tabs-height: 60px;
$pull-height: 30px;

//z-index
$zindex-notification: 999999 !default;
